@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

body {
  font-size: 16px;
  color: #333333;
  font-family: 'Source Sans Pro', sans-serif;
}

.main {
    padding:0;
}

a {
  color: #333;

  &:hover {
    text-decoration: none;
  }
}

// header image
  .img-header-wrap {
    background-color: #9cb9f3;

    .img-header {
      display: block;
      margin: 0 auto;
    }
  }

// general styles
  //grey wrapper frontpage
    .grey-wrap {
        background-color: $grey;
        margin-top:80px;
        height: auto;
        padding:80px 0px;
    }

    .indholdskort {
        @extend .card-1;
        background-color: #fff;
        width: 99.5%;

        .indholskort-content {
            padding:35px 40px 20px;

            h2 {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 1.6px;
                margin:10px 0 40px;
            }

            p {
                font-size: 14px;
            }

            .btn-default {
                background-color: $orange-bg;
                border:none;
                color:#fff;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 15px;
                margin:25px 0px;
            }
        }

    }


  // visual composer std. col space
    .wpb_content_element {
    	margin-bottom: 15px;
    }

    .vc_row {
        margin-left: 0px;
        margin-right: 0px;
    }

  // underliggende template padding
    .underliggende {
      padding: 0 100px;
    }

  // visual composer team – bestyrelsen
    .bestyrelsen {

      img.ult-team-grayscale {
          display: none;
      }

      .ult-style-1 .ult-team-member-name-wrap .ult-team-member-name {
        margin: 0 0 10px 0;
      }

      h2 {
        margin: 0;
      }
    }

  // contact form 7
  input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required,
  input.wpcf7-form-control.wpcf7-text {
    width: 100%;
  }

	.indmeldelse-form {
    input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required,
    input.wpcf7-form-control.wpcf7-text {
      width: 95% !important;
      margin: 0 5px 10px 0;
      padding: 5px;
    }

    input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required.indmeldelse-form-narrow {
      width: 46.8% !important;
      margin-right: 5.5px;
    }

    textarea.wpcf7-form-control.wpcf7-textarea {
      height: 150px;
      padding: 5px;
    }
  }

  textarea.wpcf7-form-control.wpcf7-textarea {
    width: 95%;
  }



// navigation styles
  .navbar-default {
    background-color: transparent;
    margin-bottom: 75px;
    border: 0;

    .navbar-toggle {
      float: left;
      display: none;
      border: none;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }

    .navbar-nav {
      width: 100%;
      text-align: center;

      .active a {
        background-color: transparent;
        color: $orange;

        &:hover {
          color: $orange;
          background-color: transparent;
        }
      }

        li {
          float: none;
          display: inline-block;

          a {
            color: #333;
            transition: .3s;

            &:hover {
              color: $orange;
            }
          }
      }
    }
  }

    // span menu
    .icon-menu {
      display: block;
      margin-top: -18px;
      margin-left: 30px;
      text-transform: uppercase;
      font-family: 'Roboto', sans-serif;
      font-weight: 600;
      font-size: 16px;
      color: #5b606a;
    }
// -- navigation styles



// intro content

.intro-row {

  .intro-quote-outer {
    @extend .card-2;

    .intro-quote {
      color: #fff;
      padding: 75px 15px;

      p {
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 1.625;
        word-spacing: 3px;
        font-weight: 400;
      }

      h4 {
        font-size: 25px;
        font-weight: medium;
        bottom: 10% !important;
        position: absolute;
      }
    }
  }

  .intro-col {

    p {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 1.625;
      font-weight: lighter;
      margin: 30px 0 0 25px;
    }
  }
}


// helpers
  // box shadows
    .card-1 {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: all 0.2s ease-in-out;
    }

    .card-1-hover {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    .card-2 {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }

    .card-3 {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    .card-4 {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }

    .card-5 {
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }

    .img-boxshadow-1 {
      img {
        @extend .card-1;
      }
    }

    .img-boxshadow-2 {
      img {
        @extend .card-2;
      }
    }

    .img-boxshadow-3 {
      img {
        @extend .card-3;
      }
    }
  // -- box shadows
// helpers



// footer
	footer {
    margin-top: 70px;
    border-top: 1px solid #ebebeb;
    padding: 10px 0;

    p {
      margin: 0;
      font-size: 14px;
    }
	}

  body.home {
    footer {
      margin-top: 0;
    }
  }






/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

  .underliggende {
    padding: 0;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {

	.empty-space {
		height: 0;
		display: none;
	}


  .navbar-default {
    margin-bottom: 15px;

    .navbar-toggle {
      display: block;
      float: none;
      margin: 0 auto;
      padding-top: 25px;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }

    .navbar-nav {
      margin: 0;

      .active a {
        background-color: $orange;
        color: #fff;
      }

      li {
        float: none;
        display: block;

        a {

          &:hover {

          }
        }
      }
    }
  }

  nav.navbar-collapse.collapse.in,
  nav.navbar-collapse.collapse,
  nav.navbar-collapse.collapsing {
    padding: 0;
  }

    .indholdskort {
        max-width: 350px;
        margin:0 auto;
        margin-bottom: 50px;
    }


    .intro-row {

      .intro-col {

        p {
          margin: 30px 0 0 0;
        }
      }
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}
